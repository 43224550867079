<template>
  <section>
    <b-row>
      <b-col sm="12">
        <ag-grid-table ref="historicoClima" 
        :debug="true"
        :configUrl="historicoClimaConfig"
        :dataUrl="historicoClimaData"
        @selectionChanged="onSelectionChanged"
        rowSelection="multiple"
        @gridReady="gridReady"
        @filterChanged="filterChanged"
        :getRowNodeId="getRowNodeId"
        :paginationPageSize="2000"
        :cacheBlockSize="2000"
        >
        </ag-grid-table>
      </b-col>
    </b-row>
  </section>
</template>
          
<script>

import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import useApiServices from '@/services/useApiServices.js';
import axios from "@axios";
var qs = require('qs');

import {
BRow,
BCol,
BButtonToolbar,
BButtonGroup,
BButton,
BInputGroup,
BFormInput,
BFormGroup,
BFormTextarea,

BCard,
BCardTitle,
BCardSubTitle,
BCardBody,

BMedia,
BMediaAside,
BAvatar,
BMediaBody,

BOverlay,
BSpinner


} from "bootstrap-vue";



export default {
  components: {
    AgGridTable,
    BRow,
    BCol,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BFormTextarea,

    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardBody,


    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BOverlay,
    BSpinner,
  },

  methods: {

    debugChange(value) {
      console.log(value)
      let decoded = JSON.parse(value)
    },

    onGridReady(gridApi, gridColumnApi, params) {
      this.gridApi = gridApi
      console.log("Planificaciones > onGridReady", { gridApi, gridColumnApi, params })
    },

    onGetRowsLoaded(gridApi, gridColumnApi, params) {
      console.log("Planificaciones > onGetRowsLoaded", { gridApi, gridColumnApi, params })
    },
  },

  watch: {
    // whenever question changes, this function will run
    fet_name: {
      handler(newValue, oldValue) {
        newValue.mes = newValue.mes.map(function (item) {
          if (typeof item === 'object' && item.hasOwnProperty('value')) {
            return item.value;
          } else if (typeof item === 'string') {
            return item;
          }
        });
        newValue.anho = newValue.anho.map(function(item) {
          if (typeof item === 'object' && item.hasOwnProperty('value')) {
            return item.value;
          } else if (typeof item === 'string') {
            return item;
          }
        });
        let query = qs.stringify({ fet_name: newValue })

        // console.log(query)

        this.historicoClimaConfig = useApiServices.historicoData.clima.reporteConfig + "?" + query
        this.historicoClimaData = useApiServices.historicoData.clima.reporteData + "?" + query
        this.$refs['historicoClima'].refreshRows([], true);
      },
    }
  },

  props: {

    fet_name: {
      type: String,
      required: true,
      default: null
    },
  },

  mounted() {

  },

  created() {

  },


  data() {

    let query = qs.stringify({ fet_name: this.fet_name })

    return {
      showOverlay: false,
      overlayMessage: "Cargando...",

      gridApi: null,

      historicoClimaConfig: useApiServices.historicoData.clima.reporteConfig + "?" + query,
      historicoClimaData: useApiServices.historicoData.clima.reporteData + "?" + query,
    };
  },
};
</script>
<style lang="scss" scoped>
span {
  font-size: 14px;
}
</style>
        
        
<style lang="scss" >
@import "@core/scss/vue/libs/vue-slider.scss";

.escenario-base {
  background-color: #00bcd4 !important;
}
</style>
        
<style lang="scss" scoped>
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>
          