<template>
  <section>
    <b-row>
      <b-col>
        <BrunaForm ref="searchRef" :fields="this.search.fields" :card="this.search.card">
          <template v-slot:footer>
            <b-button variant="primary" class="mt-4 float-right" @click="busqueda_historico">Buscar</b-button>
          </template>
        </BrunaForm>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-tabs>
          <b-tab title="Biometría" lazy>
            <biometria-tab :fet_name="fet_name"></biometria-tab>
          </b-tab>

          <b-tab title="Riego">
            <riego-tab :fet_name="fet_name"></riego-tab>
          </b-tab>

          <b-tab title="Coeficiente Uniformidad" lazy>
            <coeficiente-uniformidad-tab :fet_name="fet_name"></coeficiente-uniformidad-tab>
          </b-tab>

          <b-tab title="Fertilización">
            <fertilizacion-tab :fet_name="fet_name"></fertilizacion-tab>
          </b-tab>

          <b-tab title="Pre-Cosechas" lazy>
            <precosechas-tab :fet_name="fet_name"></precosechas-tab>
          </b-tab>

          <b-tab title="Maestro Parcelas" lazy>
            <maestro-parcelas-tab :fet_name="fet_name"></maestro-parcelas-tab>
          </b-tab>

          <b-tab title="Clima" lazy>
            <clima-tab :fet_name="fet_name"></clima-tab>
          </b-tab>



        </b-tabs>
      </b-col>
    </b-row>

  </section>
</template>
        
<script>

import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'


import Stat from '@/views/clients/base/components/Stat.vue'

import useApiServices from '@/services/useApiServices.js';
import BrunaForm from "@/views/brunacomponents/BrunaForm2.vue";

import BiometriaTab from './tabs/BiometriaTab.vue'
import RiegoTab from './tabs/RiegoTab.vue'
import FertilizacionTab from './tabs/FertilizacionTab.vue'
import PrecosechasTab from './tabs/PrecosechasTab.vue'
import CoeficienteUniformidadTab from './tabs/CoeficienteUniformidadTab.vue'
import MaestroParcelasTab from './tabs/MaestroParcelasTab.vue'
import ClimaTab from './tabs/ClimaTab.vue'

import axios from "@axios";
import vSelect from 'vue-select'

var qs = require('qs');

import {
  BRow,
  BCol,
  BButtonToolbar,
  BButtonGroup,
  BButton,
  BInputGroup,
  BFormInput,
  BFormGroup,
  BFormTextarea,

  BCard,
  BCardTitle,
  BCardSubTitle,
  BCardBody,

  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,

  BTabs,
  BTab,

  BOverlay,
  BSpinner


} from "bootstrap-vue";



export default {
  components: {
    AgGridTable,
    BRow,
    BCol,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BFormTextarea,

    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardBody,


    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,

    Stat,

    BOverlay,
    BSpinner,

    BrunaForm,

    BTabs,
    BTab,

    BiometriaTab,
    RiegoTab,
    CoeficienteUniformidadTab,
    FertilizacionTab,
    PrecosechasTab,
    MaestroParcelasTab,
    ClimaTab,
    vSelect
  },

  methods: {

    findFieldByName(fields, name) {

      return fields.find(field => field.id == name)

    },



    searchChange(field) {
      /*  console.log("searchChange", field);
 
       if(field.id == "mdf")
       {
 
         let fnd_field = this.findFieldByName(this.search.fields, 'fnd')
         let eqp_field = this.findFieldByName(this.search.fields, 'eqp')
         let trn_field = this.findFieldByName(this.search.fields, 'trn')
         let safra_field = this.findFieldByName(this.search.fields, 'safra')
 
         fnd_field.options = [{ value: null, text: "Selecciona una opción" }]
         fnd_field.value = null
 
         eqp_field.options = [{ value: null, text: "Selecciona una opción" }]
         eqp_field.value = null
 
         trn_field.options = [{ value: null, text: "Selecciona una opción" }]
         trn_field.value = null
 
         safra_field.options = [{ value: null, text: "Selecciona una opción" }]
         safra_field.value = null
         
 
 
         let keys = Object.keys(this.searchResults[field.value]);
         keys.sort();
 
         let options = [
           { value: null, text: "Selecciona una opción" }
         ]
 
         keys.forEach(item => {
           options.push({ value: item, text: item })
         })        
 
         fnd_field.options = options;
 
 
       }
 
       if(field.id == "fnd")
       {
 
         let mdf_field = this.findFieldByName(this.search.fields, 'mdf')
         let eqp_field = this.findFieldByName(this.search.fields, 'eqp')
         let trn_field = this.findFieldByName(this.search.fields, 'trn')
         let safra_field = this.findFieldByName(this.search.fields, 'safra')
 
         eqp_field.options = [{ value: null, text: "Selecciona una opción" }]
         eqp_field.value = null
 
         trn_field.options = [{ value: null, text: "Selecciona una opción" }]
         trn_field.value = null
 
         safra_field.options = [{ value: null, text: "Selecciona una opción" }]
         safra_field.value = null
 
 
         let keys = Object.keys(this.searchResults[mdf_field.value][field.value]);
         keys.sort();
 
         let options = [
           { value: null, text: "Selecciona una opción" }
         ]
 
         keys.forEach(item => {
           options.push({ value: item, text: item })
         })        
 
         eqp_field.options = options;
 
 
       }
 
 
       if(field.id == "eqp")
       {
 
         let mdf_field = this.findFieldByName(this.search.fields, 'mdf')
         let fnd_field = this.findFieldByName(this.search.fields, 'fnd')        
         let trn_field = this.findFieldByName(this.search.fields, 'trn')
         let safra_field = this.findFieldByName(this.search.fields, 'safra')
 
         trn_field.options = [{ value: null, text: "Selecciona una opción" }]        
         trn_field.value = null
 
         safra_field.options = [{ value: null, text: "Selecciona una opción" }]        
         safra_field.value = null
 
 
 
         let keys = Object.keys(this.searchResults[mdf_field.value][fnd_field.value][field.value]);
         keys.sort();
 
         let options = [
           { value: null, text: "Selecciona una opción" }
         ]
 
         keys.forEach(item => {
           options.push({ value: item, text: item })
         })        
 
         trn_field.options = options;
 
 
       }     */

      if (field.id == "mdf") {
        let mdf_field = this.findFieldByName(this.search.fields, 'mdf')
        let fnd_field = this.findFieldByName(this.search.fields, 'fnd')
        let eqp_field = this.findFieldByName(this.search.fields, 'eqp')
        let anho_field = this.findFieldByName(this.search.fields, 'anho')

        anho_field.options = [{ value: null, text: "Selecciona una opción" }]
        anho_field.value = null




        let keys = Object.keys(this.searchResults[mdf_field.value][fnd_field.value][eqp_field.value][field.value]);
        keys.sort();

        let options = [
          { value: null, text: "Selecciona una opción" }
        ]
        // console.log('KEY => ', keys);

        keys.forEach(item => {
          options.push({ value: item, text: item })
        })

        anho_field.options = options;


      }
    },

    busqueda_historico(event) {
      let data = this.search.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {})
      Promise.all([
        this.$refs.searchRef.validate(),
      ]).then(([queryValid]) => {
        if (queryValid) {

          // console.log(data);
          // console.log("fet_name: ", data)
          this.fet_name = data
        }
      });
    },

    roundValue(value, decimales = 2) {
      let tmp = Math.pow(10, decimales)

      return Math.round(value * tmp) / tmp
    },

    onGridReady(gridApi, gridColumnApi, params) {
      this.gridApi = gridApi
      console.log("Planificaciones > onGridReady", { gridApi, gridColumnApi, params })
    },

    onGetRowsLoaded(gridApi, gridColumnApi, params) {
      console.log("Planificaciones > onGetRowsLoaded", { gridApi, gridColumnApi, params })
    },
  },

  mounted() {

    axios.request({
      method: "get",
      url: useApiServices.historicoData.search,
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => {
        this.searchResults = response.data;


        let options = [
          { value: null, text: "Selecciona una opción" }
        ]

        let keys = Object.keys(this.searchResults);
        keys.forEach(item => {
          options.push({ value: item, text: item })
        })

        console.log('mounted => ', response)

        let mdf_field = this.findFieldByName(this.search.fields, 'mdf')

        mdf_field.options = options;
        mdf_field.value = 'ML';
      })

  },

  created() {

  },

  data() {
    let debug = (this.$route.query.debug == '1')

    return {


      showOverlay: false,
      overlayMessage: "Cargando...",

      gridApi: null,
      // historicoAutomationResultsConfig: useApiServices.historicoAutomationResultsConfig,
      // historicoAutomationResultsData: useApiServices.historicoAutomationResultsData,

      stats: [],

      fet_name: null,
      searchResults: [],


      search: {
        card: {
          title: "Búsqueda",
          subTitle: "",
          tooltip: "",
          sidebarContent: {
            title: "Histórico de Data",
            body: '<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="">En esta vista podr&aacute;s revisar toda la data hist&oacute;rica que Bruna utiliza para hacer las predicciones a nivel de Fundo-Equipo-Turno-Soca.</span></span></span></p><p><span style="font-size:11pt"><span style="font-family:Arial"><span style="">Todos los d&iacute;as se consulta la base de datos y se actualizan los registros de los &uacute;ltimos 7 d&iacute;as.</span></span></span></p><p><span style="font-size:11pt"><span style="font-family:Arial"><span style="">Si deseas buscar informaci&oacute;n en particular, en la secci&oacute;n b&uacute;squeda podr&aacute;s seleccionar la informaci&oacute;n que necesitas para posteriormente visualizar el hist&oacute;rico en formato tabla y gr&aacute;ficos de biometr&iacute;a, riego y coeficiente de uniformidad.&nbsp;</span></span></span></p>'
          }
        },
        fields: [


          {
            label: "MDF",
            type: "select",
            id: 'mdf',
            rules: 'required',
            options: [
              { value: 'select_value', text: "Selecciona una opción" },
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },
          {
            label: "Año",
            type: "vselect",
            id: 'anho',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opcion" },
              { value: '2015', text: '2015' },
              { value: '2016', text: '2016' },
              { value: '2017', text: '2017' },
              { value: '2018', text: '2018' },
              { value: '2019', text: '2019' },
              { value: '2020', text: '2020' },
              { value: '2021', text: '2021' },
              { value: '2022', text: '2022' },
              { value: '2023', text: '2023' }
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },
          {
            label: "Mes",
            type: "vselect",
            id: 'mes',
            rules: '',
            options: [
              { value: null, text: "Selecciona una opción" },
              { value: '01', text: '1' },
              { value: '02', text: '2' },
              { value: '03', text: '3' },
              { value: '04', text: '4' },
              { value: '05', text: '5' },
              { value: '06', text: '6' },
              { value: '07', text: '7' },
              { value: '08', text: '8' },
              { value: '09', text: '9' },
              { value: '10', text: '10' },
              { value: '11', text: '11' },
              { value: '12', text: '12' }
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

        ]
      },
    };
  },
};
</script>
<style lang="scss" scoped>
span {
  font-size: 14px;
}
</style>
      
      
<style lang="scss" >
@import "@core/scss/vue/libs/vue-slider.scss";

.escenario-base {
  background-color: #00bcd4 !important;
}
</style>
      
<style lang="scss" scoped>
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>
        